import React from "react"
import AboutUs from "../sections/about"

const Wrapper = () => {
	return (
		<>
			<AboutUs />
		</>
	)
}

export default Wrapper
