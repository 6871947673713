import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { css } from "@emotion/core"
import styled from "@emotion/styled"

import Inner from "../../../components/Inner"
import Info from "../../../components/Info"
import CrewCard from "../../../components/CrewCard"

const List = styled.div`
	display: grid;
	grid-column-gap: 32px;
	grid-row-gap: 64px;
	grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
	grid-auto-rows: min-content;
`

const Content = styled.div`
	padding: 2em 0;
`

const Crews = styled.div``

const Section = () => {
	const data = useStaticQuery(graphql`
		{
			allStrapiCrews(sort: { fields: order, order: ASC }) {
				edges {
					node {
						fullName
						role
						avatar {
							publicURL
						}
					}
				}
			}
		}
	`)

	const crews = data.allStrapiCrews.edges

	return (
		<Crews>
			<Inner>
				<Content>
					<Info
						title={"Crews"}
						detail={`We are strong individuals who enthusing in
								developing and improving the technologies and
								industry in Cambodia.
					`}
						center
						css={css`
							margin-bottom: 4em;
						`}
					/>
					<List>
						{crews.map((item, index) => (
							<CrewCard
								key={index}
								name={item.node.fullName}
								role={item.node.role}
								imageUrl={item.node.avatar.publicURL}
							/>
						))}
					</List>
				</Content>
			</Inner>
		</Crews>
	)
}

export default Section
