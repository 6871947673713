import React from "react"
import styled from "@emotion/styled"

const Partner = styled.div`
	user-select: none;
	min-width: 200px;
	min-height: 200px;

	overflow: hidden;
	position: relative;
	object-fit: cover;
	border-radius: 8px;

	display: inline-flex;
	justify-content: center;
	align-items: center;
`

const Image = styled.img`
	pointer-events: none;
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: -1;
`

const Detail = styled.div`
	display: none;
`

const PartnerWrapper = ({ name, imageUrl, ...props }) => {
	return (
		<Partner {...props}>
			<Image src={imageUrl} alt={name} />
			<Detail>{name}</Detail>
		</Partner>
	)
}

export default styled(PartnerWrapper)``
