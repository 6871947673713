import React from "react"
import tw from "twin.macro"
import styled from "@emotion/styled"

import * as Color from "../../utils/color"

const Image = styled.img`
	${tw`mb-2`}
	border-radius: 8px;
	height: 400px;
	object-fit: cover;
	width: 100%;
`

const Name = styled.div`
	font-weight: bolder;
	color: ${Color.primary};
`

const Role = styled.div`
	${tw`text-sm`}
	color: ${Color.primary};
`

const Crew = styled.div`
	border-radius: 8px;
`

const CrewCard = ({ name, role, imageUrl, ...props }) => {
	return (
		<Crew {...props}>
			<Image src={imageUrl} alt={name} />
			<Name>{name}</Name>
			<Role>{role}</Role>
		</Crew>
	)
}

export default styled(CrewCard)``
