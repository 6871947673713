import React from "react"
import styled from "@emotion/styled"
import Crews from "./crews"
import Partners from "./partners"
import WeAre from "./we-are"
import Mission from "./mission"

const About = styled.section``

const Content = styled.div`
	padding: 2em 0;
	display: grid;
	grid-row-gap: 4em;
`

const AboutSection = () => {
	return (
		<About>
			<Content>
				<WeAre />
				<Mission />
				<Crews />
				<Partners />
			</Content>
		</About>
	)
}

export default AboutSection
