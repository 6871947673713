import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "@emotion/styled"

import Inner from "../../../components/Inner"
import Info from "../../../components/Info"

const Image = styled.img`
	max-height: calc(62vh);
	width: 100%;
	max-width: 100%;
	height: auto;
	object-fit: contain;
	justify-self: flex-end;
	-webkit-align-self: center;
	-ms-flex-item-align: center;
	align-self: center;
`

const Content = styled.div`
	display: grid;
	grid-template-areas: "left right";
	grid-auto-columns: 1fr;

	${Info} {
		grid-area: right;
	}

	${Image} {
		grid-area: left;
	}
`

const Mission = styled.div``

const Section = () => {
	const data = useStaticQuery(graphql`
		{
			strapiAboutUsPage {
				sectionTwo {
					detail
					head
					image {
						publicURL
					}
				}
			}
		}
	`)

	const section = data.strapiAboutUsPage.sectionTwo

	return (
		<Mission>
			<Inner>
				<Content>
					<Info title={section.head} detail={section.detail} right fullWidth />
					<Image src={section.image.publicURL} />
				</Content>
			</Inner>
		</Mission>
	)
}

export default Section
