import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "@emotion/styled"

import Inner from "../../../components/Inner"
import Info from "../../../components/Info"
import PartnerItem from "../../../components/PartnerItem"

const List = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: center;

	${PartnerItem}:not(:last-of-type) {
		margin-right: 16px;
	}
`

const Content = styled.div`
	padding: 2em 0;
`

const Partners = styled.div``

const Section = () => {
	const data = useStaticQuery(graphql`
		{
			allStrapiCompanyPartners {
				edges {
					node {
						name
						logo {
							publicURL
						}
					}
				}
			}
		}
	`)

	const partners = data.allStrapiCompanyPartners.edges

	return (
		<Partners>
			<Inner>
				<Content>
					<Info title={"Partners"} detail={`We are partnering with these amazing companys.`} center />
					<List>
						{partners.map((item, index) => (
							<PartnerItem key={index} name={item.node.name} imageUrl={item.node.logo.publicURL} />
						))}
					</List>
				</Content>
			</Inner>
		</Partners>
	)
}

export default Section
